/* eslint-disable eqeqeq */
import { CancelOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import { BsPlus } from "react-icons/bs";
import { uploadDocument } from "../../../Api/uploadFile";
import { SetSkill } from "../../../firebase/setSkills";

const AddNewSkill = ({ isAdd, setIsAdd }) => {
  const [skill, setSkill] = React.useState("");
  const [img, setImg] = React.useState(null);
  const [previewImg, setPreviewImg] = React.useState("");
  const [type, setType] = React.useState("development");

  const handleImageChange = (file) => {
    setImg(file);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setPreviewImg(reader.result);
      }
    };
    reader.readAsDataURL(file);

    // setImgUrl(url);
  };

  const handleEditSave = async () => {
    if (previewImg) {
      const url = uploadDocument(img, `/portfolio/skills/${skill + type}`);
      url.then((fileUrl) => {
        // console.log({ name: skill, type, image: fileUrl, id: skill + type });
        setIsAdd(!isAdd);
        SetSkill({
          name: skill,
          type,
          image: fileUrl,
          id: skill + type,
        }).then((res) => {
          //   console.log("res: ", res);
          alert("Update Successfull!");
        });
      });
    } else {
      alert("Please Upload Image");
    }
  };
  const res = (
    <Stack
      direction="row"
      spacing={4}
      alignItems="center"
      sx={{ border: "1px solid green", width: "100%", p: 4, my: 2 }}
    >
      <Box>
        <Box sx={{ overflow: "hidden", width: "250px" }}>
          <img
            src={previewImg}
            height="60px"
            alt="skill"
            style={{ margin: "10px 0" }}
          />
          <input
            type="file"
            accept="image/*"
            style={{ marginTop: "10px" }}
            onChange={(e) => handleImageChange(e.target.files[0])}
          />
        </Box>
      </Box>
      <Box>
        <TextField
          onChange={(e) => {
            setSkill(e.target.value);
          }}
          id="standard-basic"
          label=""
          variant="standard"
          defaultValue={""}
        />
      </Box>
      <Box>
        <TextField
          id="standard-select-currency-native"
          select
          label=""
          onChange={(e, val) => {
            setType(e.target.value);
          }}
          // defaultValue={""}
          SelectProps={{
            native: true,
          }}
          helperText=""
          variant="standard"
        >
          <option value={"development"} selected>
            Development
          </option>
          <option value={"design"}>Design</option>
          <option value={"tools"}>Tools</option>
        </TextField>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <>
            <Button
              startIcon={<BsPlus fontSize={25} fontWeight={800} />}
              variant="outlined"
              size="sm"
              color="success"
              onClick={handleEditSave}
            >
              Add
            </Button>
            <IconButton
              // variant="outlined"
              size="sm"
              color="error"
              onClick={() => setIsAdd(!isAdd)}
            >
              <CancelOutlined />
            </IconButton>
          </>
        </Stack>
      </Box>
    </Stack>
  );
  return res;
};

export default AddNewSkill;
