import { useRef } from "react";
import { Text, Group, createStyles, rem, SimpleGrid } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { Delete, DownloadOutlined } from "@mui/icons-material";
import { GiCrossMark } from "react-icons/gi";
// import { IconCloudUpload, IconX, IconDownload } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    marginBottom: rem(30),
  },

  dropzone: {
    borderWidth: rem(1),
    paddingBottom: rem(50),
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: "absolute",
    width: rem(250),
    left: `calc(50% - ${rem(125)})`,
    bottom: rem(-20),
  },
}));

export function DropzoneButton({ selectedImages, setSelectedImages }) {
  const { classes } = useStyles();
  const openRef = useRef(null);
  const handleImageChange = (files) => {
    if (files && files.length > 0) {
      const newImages = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const blobUrl = URL.createObjectURL(file);
        newImages.push({ file, blobUrl });
      }
      setSelectedImages([...selectedImages, ...newImages]);
    }
  };

  const handleImageDelete = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  //   useEffect(() => {
  //     handleImageChange(files);
  //   }, [files]);
  return (
    <div className={classes.wrapper}>
      <Dropzone
        openRef={openRef}
        onDrop={(data) => {
          //   let files = [...selectedImages, ...data];
          handleImageChange(data);
          //   setFiles((prevFiles) => [...prevFiles, ...data]);
        }}
        className={classes.dropzone}
        radius="md"
        accept={[
          MIME_TYPES.png,
          MIME_TYPES.jpeg,
          MIME_TYPES.gif,
          MIME_TYPES.webp,
        ]}
        maxSize={30 * 1024 ** 2}
      >
        <div style={{ pointerEvents: "none" }}>
          <Group position="center">
            <Dropzone.Accept>
              <DownloadOutlined />
              {/* <IconDownload
                size={rem(50)}
                color={theme.colors[theme.primaryColor][6]}
                stroke={1.5}
              /> */}
            </Dropzone.Accept>
            <Dropzone.Reject>
              {/* <IconX size={rem(50)} color={theme.colors.red[6]} stroke={1.5} /> */}
              <GiCrossMark size={rem(50)} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              {/* <IconCloudUpload
                size={rem(50)}
                color={
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.black
                }
                stroke={1.5}
              /> */}

              <DownloadOutlined />
            </Dropzone.Idle>
          </Group>

          <Text ta="center" fw={700} fz="lg" mt="xl">
            <Dropzone.Accept>Drop files here</Dropzone.Accept>
            <Dropzone.Reject>Image file less than 20mb</Dropzone.Reject>
            <Dropzone.Idle>Upload Project Images</Dropzone.Idle>
          </Text>
          <Text ta="center" fz="sm" mt="xs" c="dimmed">
            Drag&apos;n&apos;drop files here to upload. We can accept only{" "}
            <i>.png/.gif/.jpeg/.webp </i> files that are less than 20mb in size.
          </Text>
        </div>
      </Dropzone>
      <SimpleGrid cols={3} mt="md">
        {/* {items} */}
        {selectedImages.map((img, i) => {
          //   console.log(img);
          return (
            <div key={i} className="relative">
              <img
                src={img.blobUrl}
                alt={img.file.name}
                width="100%"
                height={250}
              />
              <button
                className=" absolute top-2 right-2"
                onClick={() => handleImageDelete(i)}
              >
                <Delete color="error" />
              </button>
            </div>
          );
        })}
      </SimpleGrid>
    </div>
  );
}

// function showImage(input) {
//   if (input.files && input.files[0]) {
//     return URL.createObjectURL(input.files[0]);
//   }
// }
