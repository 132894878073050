import React, {useContext, useEffect, useState} from "react";
// import { useForm } from "react-hook-form";
import GetProfile from "../../../firebase/getProfile";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { bucket } from "../../../firebase";
import {
  TextInput,
  Paper,
  Title,
  Container,
  Button,
  Textarea,
} from "@mantine/core";
import { SetProfile } from "../../../firebase/setProfile";
import { Avatar, IconButton, Input, Stack } from "@mui/material";
import { useForm } from "@mantine/form";
import {AuthContext} from "../../../context/authContext";

const AdminProfile = () => {
  const [imgUrl, setImgUrl] = useState("");
  const {user} = useContext(AuthContext);
  console.log({user})
  const { adminInfo } = GetProfile();
  useEffect(() => {
    adminInfo?.photoURL ? setImgUrl(adminInfo?.photoURL) : setImgUrl("");
  }, [adminInfo]);
  const onSubmit = (data) => {
    data = {
      ...data,
      resume: data.resume ?? adminInfo.resume,
      linkedin: data.linkedin ?? adminInfo.linkedin,
      fullName: data.name ?? adminInfo.name,
      about: data.about ?? adminInfo.about,
      github: data.github ?? adminInfo.github,
      photoURL: imgUrl,
    };
    // data["photoURL"] = imgUrl;

    // console.log("data: ", data);
    SetProfile(data, user).then((result) =>
      result
        ? (() => {
            alert("Profile Updated Successfully");
            // toast({
            //   title: "Profile Updated",
            //   description: "",
            //   status: "success",
            //   duration: 9000,
            //   isClosable: true,
            // });
          })()
        : alert("Profile Not Updated!!!!")
    );
  };
  const fileHandler = (event) => {
    uploadDocument(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = function (e) {
      // console.log(e.target.result);
      setImgUrl(e.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };
  const uploadDocument = (file) => {
    const storageRef = ref(bucket, `/portfolio/dp/rid1xyzdp`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        alert(error.message);
        console.log("~~ error ~~", error);
        // toast({
        //   title: `something error`,
        //   status: "error",
        //   isClosable: true,
        // });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          alert("Image uploaded successfully");
          // toast({
          //   title: "Image Uploaded.",
          //   status: "success",
          //   duration: 3000,
          //   isClosable: true,
          // });
          setImgUrl(downloadURL);
        });
      }
    );
  };

  const form = useForm({
    initialValues: {
      resume: adminInfo.resume,
      linkedin: adminInfo.linkedin,
      name: adminInfo.name,
      about: adminInfo.about,
      github: adminInfo.github,
    },

    // validate: {
    //   email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    // },
  });
  return (
    <div>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Container my={10}>
          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 900,
            })}
          >
            Edit Profile
          </Title>

          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <Stack direction={"row"} justifyContent={"center"}>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  className="d-none"
                  onChange={fileHandler}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <Avatar
                    sx={{ width: 136, height: 136 }}
                    alt="Ridwan Alam"
                    src={imgUrl}
                  />
                </IconButton>
              </label>
            </Stack>
            <TextInput
              label="Full Name"
              {...form.getInputProps("name")}
              defaultValue={adminInfo.name}
              required
            />
            <TextInput
              label="Resume link"
              required
              defaultValue={adminInfo.resume}
              {...form.getInputProps("resume")}
            />
            <TextInput
              label="Github link"
              required
              defaultValue={adminInfo.github}
              {...form.getInputProps("github")}
            />
            <TextInput
              label="LinkedIn link"
              required
              defaultValue={adminInfo.linkedin}
              {...form.getInputProps("linkedin")}
            />
            <Textarea
              label="About"
              required
              defaultValue={adminInfo.about}
              withAsterisk
              autosize
              minRows={5}
              {...form.getInputProps("about")}
            />
            <Button color="green.9" className="bg-gray-800" fullWidth mt="xl" type="submit">
              Update Profile
            </Button>
          </Paper>
        </Container>
      </form>
    </div>
  );
};

export default AdminProfile;
