// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "ridwan-dev.firebaseapp.com",
  databaseURL: process.env.FIREBASE_DB_URL,
  projectId: "ridwan-dev",
  storageBucket: "ridwan-dev.appspot.com",
  messagingSenderId: "600103325670",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-9YFKC56BHB",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = getDatabase();
const bucket = getStorage(app);
// const fbFunctions = getFunctions(app);
const auth = getAuth(app);

export { db, bucket, auth };
