import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import "react-vertical-timeline-component/style.min.css";
import "./exp.css";
import { experiences } from "./_data_";
import { SectionWrapper } from "../../hoc";
import { textVariant } from "../../utils/motion";
import {
  Accordion,
  Container,
  createStyles,
  rem,
  getStylesRef,
} from "@mantine/core";
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xs})`,
    minHeight: rem(80),
    // backgroundImage: `radial-gradient(${
    //   theme.colors[theme.primaryColor][6]
    // } 0%, ${theme.colors[theme.primaryColor][4]} 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
    position: "relative",
    color: theme.black,
  },

  item: {
    backgroundColor: theme.white,
    borderBottom: 0,
    border: 0,
    borderRadius: theme.radius.sm,
    // boxShadow: theme.shadows.sm,
    overflow: "hidden",
  },

  control: {
    fontSize: theme.fontSizes.lg,
    padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
    color: theme.black,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  content: {
    paddingLeft: theme.spacing.xl,
    lineHeight: 1.6,
    color: theme.black,
  },

  icon: {
    ref: getStylesRef("icon"),
    marginLeft: theme.spacing.md,
  },

  gradient: {
    backgroundImage: `radial-gradient(${theme.colors[theme.black]} 0%, ${
      theme.colors[theme.black]
    } 100%)`,
  },

  itemOpened: {
    [`& .${getStylesRef("icon")}`]: {
      transform: "rotate(45deg)",
    },
  },

  button: {
    display: "block",
    marginTop: theme.spacing.md,

    [theme.fn.smallerThan("sm")]: {
      display: "block",
      width: "100%",
    },
  },
}));
const ExperienceCard = ({ experience }) => {
  const { classes } = useStyles();
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "#fff",
        boxShadow: "0px 0px 50px -20px rgba(0,0,0,0.14)",
        color: "#000",
      }}
      contentArrowStyle={{ borderRight: "7px solid  #232631" }}
      date={experience.date}
      dateClassName="date"
      iconStyle={{ background: experience.iconBg }}
      icon={
        <a href={experience?.company_link} target="_blank" rel="noreferrer">
          {" "}
          <div className="company-logo-container">
            {" "}
            <img
              src={experience.icon}
              alt={experience.company_name}
              className=""
            />
          </div>
        </a>
      }
    >
      <div>
        <a href={experience?.company_link} target="_blank" rel="noreferrer">
          {" "}
          <h3 className="text-black text-[18px] font-bold">
            {experience.company_name}
          </h3>
        </a>
        <p
          className="text-black text-[16px] font-semibold"
          style={{ margin: 0 }}
        >
          {/* <a href={experience?.company_link} target="_blank" rel="noreferrer"> */}
          {experience.title}
          {/* </a>{" "} */}
        </p>
      </div>
      <div className={classes.wrapper}>
        <Container>
          <Accordion
            chevronPosition="right"
            // multiple
            defaultValue={experience.company_name}
            chevronSize={20}
            transitionDuration={800}
            variant="separated"

            // disableChevronRotation
            // chevron={
            //   <ThemeIcon radius="xl" className={classes.gradient} size={42}>
            //     <BiPlus size="2.05rem" stroke={1.5} />
            //   </ThemeIcon>
            // }
          >
            <Accordion.Item
              className={`${classes.item}`}
              value={experience.company_name}
            >
              <Accordion.Control className="text-[16px] text-gray-700 hidden md:flex p-0 pr-4">
                Job Responsibilities :
              </Accordion.Control>

              <Accordion.Control className="text-[13px] block md:hidden">
                Responsibilities :
              </Accordion.Control>
              <Accordion.Panel className="">
                <ul className="list-disc ml-2 space-y-2">
                  {experience.points.map((point, index) => (
                    <li
                      key={`experience-point-${index}`}
                      className="text-black text-[14px] md:text-[18px] md:tracking-wider"
                    >
                      {point}
                    </li>
                  ))}
                </ul>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </VerticalTimelineElement>
  );
};

const ExperienceV2 = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <h1 className="text-roboto text-center text-uppercase mb-2 md-mb-0">
          Experience
        </h1>
      </motion.div>
      <div className="mt-10 flex flex-col ">
        <VerticalTimeline lineColor="#181717">
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default SectionWrapper(ExperienceV2, "work");
