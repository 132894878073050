import cogoToast from "cogo-toast";
import { ref, update } from "firebase/database";
import { db } from "../firebase";

export const updateProject = async (data) => {
  const { title, img, desc, gitLink, liveLink, tools, id, job, sequence } =
    data;
  const body = {
    title: title ? title : "",
    desc: desc ? desc : "",
    img: img ? img : "",
    gitLink: gitLink ? gitLink : "",
    liveLink: liveLink ? liveLink : "",
    sequence: sequence,
    tools: tools ? tools : [],
    id: id,
    job: job,
  };
  const updates = {};
  updates["projects/id-" + id + "/"] = body;

  //   return set(ref(db, "projects/id-" + id + "/"), body)
  return update(ref(db), updates)
    .then(() => {
      cogoToast.success(
        <div>
          <b>Awesome!</b>
          <div>Project Updated.</div>
        </div>
      );
      return true;
    })
    .catch((error) => {
      cogoToast.error(
        <div>
          <b>Somethng Bad Happedt!</b>
          <div>{error?.message}</div>
        </div>
      );
      return false;
    });
};
