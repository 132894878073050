import { ref, set } from "firebase/database";
import { db } from "../firebase";

export const SetSkill = async (data) => {
  return set(ref(db, "skills/" + data.id), data)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};
