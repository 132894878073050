import { ref, set } from "firebase/database";
import { db } from "../firebase";

export const SetProfile = (data) => {
  const { fullName, photoURL, about, github, linkedin, resume } = data;
  const setItems = {
    name: fullName ? fullName : "",
    about: about ? about : "",
    photoURL: photoURL ? photoURL : "",
    github: github ? github : "",
    resume: resume ? resume : "",
    linkedin: linkedin ? linkedin : "",
  };
  return set(ref(db, "profile/"), setItems)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};
