import React, { useContext } from "react";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Title,
  Container,
  Group,
  Button,
  Notification,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { signInWithEmailAndPassword } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { Error } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
const Login2 = () => {
  const { setUser } = useContext(AuthContext);
  // console.log("user: ", user, setUser);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(null);
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (value ? null : "Password is required"),
    },
  });
  const onSubmit = (data) => {
    setIsLoading(true);
    setIsError(null);
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        setUser(userCredential.user);
        setIsLoading(false);
        // setIsAdmin(true);
        navigate("/admin");
        // history.push("/admin");

        // sessionStorage.setItem("login", userCredential.operationType);
      })
      .catch((error) => {
        console.log("Sign in error", { error });
        setIsError(error.message);
        setIsLoading(false);
      });
  };
  return (
    <Container size={420} my={0} py={100}>
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Admin Login
      </Title>

      {/* <Text color="dimmed" size="sm" align="center" mt={5}>
          Do not have an account yet?{' '}
          <Anchor<'a'> href="#" size="sm" onClick={(event) => event.preventDefault()}>
            Create account
          </Anchor>
        </Text> */}

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            label="Email"
            color={"gray.9"}
            placeholder="mail@qridwan.com"
            required
            withAsterisk
            {...form.getInputProps("email")}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            color={"gray.9"}
            mt="md"
            {...form.getInputProps("password")}
          />
          <Group position="apart" mt="lg">
            <Checkbox
              label="Remember me"
              color={"gray.9"}
              sx={{ lineHeight: 1 }}
              {...form.getInputProps("remember", { type: "checkbox" })}
            />
          </Group>

          <Button
            type="submit"
            disabled={isLoading}
            fullWidth
            mt="xl"
            className="bg-gray-700"
            color={"gray.9"}
          >
            {isLoading ? "Signin..." : "Sign in"}
          </Button>
          {isError && (
            <Notification
              my={10}
              onClick={() => setIsError(null)}
              icon={<Error size={18} onClick={() => setIsError(null)} />}
              color="red"
            >
              {isError}{" "}
            </Notification>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default Login2;
