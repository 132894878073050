import { useContext } from "react";
import { AuthContext } from "../context/authContext";

export default function useAuth() {
  const { user } = useContext(AuthContext);
  //   console.log("user: ", user);
  if (user?.email === "hello@rid1.xyz" && user?.accessToken) {
    return true;
  } else {
    return false;
  }
}
