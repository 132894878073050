import { bipolarFactory, adventure } from "../../assets/company";
const getDuration = (date, end) => {
  const start = new Date(date);
  // Calculate the difference in total months
  let months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth();
  // Calculate the number of years
  const years = Math.floor(months / 12);
  months = months % 12;
  return years > 0
    ? years + " year(s), " + months + " month(s)"
    : "~" + months + " month(s)";
};

export const experiences = [
  {
    title: "Software Engineer",
    date: `May 2024 - Present  [${getDuration("2024-5-1", new Date())}] `,
    company_name: "Adventure Dhaka Ltd.",
    company_link: "https://adventurekk.com",
    icon: adventure,
    iconBg: "#1C5EB5",
    points: [
      `Develop front-end programming and logic utilizing React.js, Next.js, Php, Laravel, and Node.js`,
      `Contribute to the Vue.js or React.js project structures, offering insights into best practices and project methodologies.`,
      `Conduct code reviews and share expertise with fellow developers, fostering a collaborative environment.`,
      `Collaborate within team circles on internal and external projects, ensuring smooth delivery.`,
      `Demonstrate advanced proficiency in Vue.js, React.js, JavaScript and Typescript.`,
      `Lead the development or enhancement of web-based projects independently or as a team.`,
      `Identify and rectify bugs, troubleshoot issues, and resolve challenges in existing or ongoing projects.`,
    ],
  },
  {
    title: "Full Stack Developer",
    date: `June 2021 - May 2024  [3 Years] `,
    company_name: "Bipolar Factory",
    company_link: "https://bipolarfactory.com",
    icon: bipolarFactory,
    iconBg: "black",
    points: [
      "Worked with: ReactJS, Node Js, Firebase, AWS and GCP services",
      `Collaborate with the team to thoroughly understand the product and its features.`,
      `Design, develop, and test UI for web application using HTML, CSS, and JavaScript.`,
      `Work with the team to collaboratively own the front-end layer.`,
      `Translate user and business needs into functional web application.`,
      `Maintain the quality of the code and suggest enhancements and optimizations.`,
      `Coordinate with the development team to evaluate the technical feasibility of new requirements.`,
      `Debugging and troubleshoot existing code using Chrome developer tools or Firebug.`,
      `Managing the history and versions of the code using Git`,
    ],
  },
];
