import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as profileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as projectIcon } from "../../assets/icons/projects.svg";
import { ReactComponent as skillIcon } from "../../assets/icons/skills.svg";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Backspace, Home } from "@mui/icons-material";

const drawerWidth = 280;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "black",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AdminDrawer = () => {
  // const classes = useStyles();\
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const route = pathname.split("/").pop();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  // const [showPage, setShowPage] = React.useState("Profile");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <div>
            <IconButton className="" onClick={() => navigate(-1)}>
              <Backspace color="white" className="text-white" />
            </IconButton>
            <IconButton onClick={() => navigate(1)}>
              {/* <Forward color="white" /> */}
              <Backspace
                color=""
                className="text-white"
                style={{ rotate: "180deg" }}
              />
            </IconButton>
          </div>
          <Typography variant="h5" noWrap component="div">
            Qridwan Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            {
              title: "Profile",
              icon: profileIcon,
              route: "/admin/profile",
            },
            { title: "Projects", icon: projectIcon, route: "/admin/projects" },
            { title: "Skills", icon: skillIcon, route: "/admin/skills" },
            { title: "Home", icon: Home, route: "/" },
          ].map((obj, index) => (
            <ListItem
              sx={{
                background:
                  route === obj.title.toLowerCase() ? "black" : "white",
                color: route === obj.title.toLowerCase() ? "white" : "",
                cursor: "pointer",
              }}
              key={obj.title}
              onClick={() => {
                navigate(obj.route);
              }}
            >
              <obj.icon className="mr-2" style={{}} />
              <ListItemText primary={obj.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};

export default AdminDrawer;
