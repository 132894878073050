import { createStyles, Card, Text, SimpleGrid, Image } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Fragment } from "react";
import GetSkills from "../../firebase/getSkills";
import { SectionWrapper } from "../../hoc";

// import { skills } from "./skillData";

const useStyles = createStyles((theme) => {
  const largeScreen = useMediaQuery("(min-width: 900px)");
  return {
    card: {
      // backgroundColor:
      //   theme.colorScheme === "dark"
      //     ? theme.colors.dark[3]
      //     : theme.colors.gray[0],
      minHeight: largeScreen ? "400px" : "280px",
      border: "none !important",
    },

    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 700,
      fontSize: 18,
    },

    item: {
      display: "flex",
      zIndex: "0 !important",
      position: "relative !important",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      borderRadius: theme.radius.md,
      height: 90,
      // backgroundColor: `${theme.colors.gray[0]} !important`,
      transition: "box-shadow 150ms ease, transform 100ms ease",
      background: "#fff",
      boxShadow: "0px 0px 50px -20px rgba(0,0,0,0.14)",
      color: "#000",
      "&:hover": {
        boxShadow: `${theme.shadows.md} !important`,
        transform: "scale(1.05)",
      },
    },
  };
});

export function ItemCard({ type }) {
  const largeScreen = useMediaQuery("(min-width: 900px)");
  const { classes } = useStyles();
  const { skills } = GetSkills();
  // console.log("skills: ", skills);
  const items =
    skills.length > 0 &&
    skills?.map((item) => {
      // console.log('item: ', item);
      if (item.type === type || type === "all") {
        return (
          <Fragment key={item.name}>
            {/* <div className="w-28 h-28">
              <BallCanvas icon={item.image} />
            </div> */}

            <div key={item.id} className={classes.item}>
              <Image
                src={item.image}
                alt={item.name}
                height={largeScreen ? 35 : 20}
                width={largeScreen ? 35 : 20}
              />
              <Text size={largeScreen ? "sm" : "xs"} mt={7}>
                {item.name}
              </Text>
            </div>
          </Fragment>
        );
      }
      return null;
    });

  return (
    <Card withBorder radius="md" className={classes.card}>
      {/* <Group position="apart">
        {largeScreen && (
          <Text className={classes.title}>{type.toUpperCase()} SKILLS: </Text>
        )}
      </Group> */}
      <SimpleGrid cols={largeScreen ? 8 : 4} mt="md">
        {items}
      </SimpleGrid>
    </Card>
  );
}

export default SectionWrapper(ItemCard, "");
