import React from "react";
import "./Home.css";
import Header from "./Header/Header";
import About from "./About/About";
import GetProfile from "../../firebase/getProfile";
import Loading from "./Loading";
import Skills from "../SkillSet";
import ExperienceV2 from "../Experience/ExperienceV2";
import { Helmet } from "react-helmet";

const Home = () => {
  document.title = "Ridwan's Portfolio";
  const { adminInfo } = GetProfile();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    adminInfo.length !== 0 && setLoading(false);
  }, [adminInfo.length]);
  return (
    <div className="home-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ridwan || Home</title>
      </Helmet>
      {!loading ? (
        <>
          <Header adminInfo={adminInfo} />
          <About adminInfo={adminInfo} />
          {/* <Experience /> */}
          <ExperienceV2 />
          {/* <SkillSet /> */}
          <Skills />
          <a
            id="mibew-agent-button"
            href="/chat?locale=en&amp;style=dark"
            target="_blank"
            onClick="Mibew.Objects.ChatPopups['656cfd2f0fa191a9'].open();return false;"
          >
            <img src="/b?i=mibew&amp;lang=en" border="0" alt="" />
          </a>
        </>
      ) : (
        <div className="home-top-container">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Home;
