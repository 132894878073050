import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Blogs from "./components/Blogs/Blogs";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Navigation from "./components/Navigation/Navigation";
import Projects from "./components/Projects/Projects";
import "./Media.css";
import GitActivity from "./components/GitActivity/GitActivity";
import React from "react";
import Login2 from "./components/Login/Login.v2";
import { NothingFoundBackground } from "./components/404.js/NotFound";
import Admin from "./components/Admin/Admin";
import { createTheme, ThemeProvider } from "@mui/material";
import AdminProfile from "./components/Admin/profile";
import SetProjects from "./components/Admin/projects/SetProjects";
import AdminSkills from "./components/Admin/skills";
import RequireAuth from "./routes/PrivateRoute";
import AuthContextProvider from "./context/authContext";
import EditProjects from "./components/Admin/projects/EditProject";
import AddProject from "./components/Admin/projects/AddProject";

function App() {
  const { pathname } = useLocation();
  const rootElement = document.getElementById("app");
  const theme = createTheme({
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <div className="" key={"any"}>
          <Navigation />
          <Routes>
            <Route
              path="projects"
              element={
                <>
                  <Projects />
                  <Footer />
                </>
              }
            />
            <Route
              path="contact"
              element={
                <>
                  <Contact />
                  <GitActivity />
                  <Footer />
                </>
              }
            />
            <Route
              path="blogs"
              element={
                <>
                  <Blogs />
                  <GitActivity />
                  <Footer />
                </>
              }
            />
            <Route
              path="login"
              element={
                <>
                  <Login2 />
                  <Footer />
                </>
              }
            />
            <Route
              path="admin"
              element={
                <RequireAuth>
                  <Admin />
                </RequireAuth>
              }
            >
              <Route index path="profile" element={<AdminProfile />} />
              <Route index path="projects" element={<SetProjects />} />
              <Route index path="edit-projects" element={<EditProjects />} />
              <Route index path="add-project" element={<AddProject />} />
              <Route index path="skills" element={<AdminSkills />} />
            </Route>
            <Route
              path="/"
              element={
                <>
                  <Home />
                  <GitActivity />
                  <Footer />
                </>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <NothingFoundBackground />
                </>
              }
            />
          </Routes>
        </div>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
