import React from "react";
import { CardBody, CardContainer, CardItem } from "../ui/3d-card";
import { Card, Image, Text, Group, Badge, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import classes from "./Blogs.module.css";

export function ThreeDCardDemo({ blog }) {
  const [opened, { open, close }] = useDisclosure(false);
  // eslint-disable-next-line no-unused-vars
  const { title, pubDate, link, categories, description, author } = blog || {};
  const features = categories?.map((badge) => (
    <Badge variant="light" key={badge}>
      {badge}
    </Badge>
  ));

  return (
    <CardContainer className="inter-var h-full">
      <CardBody className="bg-gray-50 h-full relative group/card  border-black/[0.1] w-auto sm:w-[30rem]  rounded-xl p-6 border  ">
        <CardItem
          translateZ="50"
          className="text-l text-start font-bold text-neutral-600 "
        >
          {title}
        </CardItem>
        <CardItem
          as="p"
          translateZ="60"
          className="text-neutral-500 text-sm max-w-sm mt-2 "
        >
          {convertDateFormat(pubDate)}
        </CardItem>
        <CardItem translateZ="100" className="w-full mt-4">
          <Image
            src={getFirstImgSrc(description)}
            alt={title}
            className=" w-full object-cover rounded-xl group-hover/card:shadow-xl"
          />
        </CardItem>

        <div className="flex justify-between items-center mt-4">
          <CardItem
            translateZ={20}
            as="button"
            onClick={() => window.open(link, "_blank")}
            className="px-4 py-2 rounded-xl text-xs font-normal "
          >
            Blog Site →
          </CardItem>
          <CardItem
            translateZ={20}
            as="button"
            onClick={open}
            className="px-4 py-2 rounded-xl bg-black   text-white text-xs font-bold"
          >
            Read More
          </CardItem>
        </div>
      </CardBody>

      {/* model for showing the full blog */}
      <Modal
        opened={opened}
        onClose={close}
        size={"100%"}
        title={
          <>
            <Group justify="apart" align="start">
              <Text mt={10} fz="lg" align="start" fw={500}>
                {title}
              </Text>
              <Badge size="sm" my={10} variant="gradient">
                {convertDateFormat(pubDate)}
              </Badge>
            </Group>
          </>
        }
        centered
      >
        {opened && (
          <Card
            withBorder
            sx={{ height: "100%", width: "100%" }}
            radius="md"
            p="md"
            className={classes.card}
          >
            <Card.Section sx={{ textAlign: "start" }} my={10} m="md">
              {/* <div dangerouslySetInnerHTML={{ __html: content }}></div> */}
              <Text fz="sm" mt="xs">
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              </Text>

              <Text mt="md" className={classes.label} c="dimmed">
                Categories
              </Text>
              <Group gap={7} my={5}>
                {features}
              </Group>
            </Card.Section>
          </Card>
        )}
      </Modal>
    </CardContainer>
  );
}
function convertDateFormat(inputDate) {
  const dateObject = new Date(inputDate);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = dateObject.toLocaleDateString("en-US", options);

  const result = ` ${formattedDate}`;

  return result;
}

function getFirstImgSrc(htmlString) {
  const regex = /<img[^>]+src\s*=\s*["']([^"']+)["'][^>]*>/i;
  const match = htmlString.match(regex);

  return match ? match[1] : "";
}
