import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./Contact.css";
import emailjs from "emailjs-com";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import cogoToast from "cogo-toast";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [isSending, setIsSending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data, e) => {
    setIsSending(true);
    const newEmail = { ...data, status: "sending" };

    emailjs
      .send(
        process.env.REACT_APP_EMAIL_ID,
        process.env.REACT_APP_EMAIL_KEY,
        newEmail,
        process.env.REACT_APP_EMAIL_SECRET
      )
      .then(
        (result) => {
          //   console.log(result);
          setIsSending(false);
          try {
            e?.target?.reset();
            cogoToast.success(
              <div>
                <b>Awesome!</b>
                <div>Mail Sent to Ridwan.</div>
              </div>
            );
          } catch (error) {
            cogoToast.error(
              <div>
                <b>Somethng breaks!</b>
                <div>Please Try again.</div>
                <div>
                  You can directly mail to{" "}
                  <a href="mailto:mail@qridwan.com">Ridwan</a>
                </div>
              </div>
            );
          }
        },
        (error) => {
          setIsSending(false);
          console.log({ error });
          cogoToast.error(
            <div>
              <b>Somethng breaks!</b>
              <div>Please Try again.</div>
              <div>
                You can directly sent email to{" "}
                <a
                  href="mailto:mail@qridwan.com"
                  className="underline font-medium text-red-600"
                >
                  Ridwan
                </a>
              </div>
            </div>
          );
        }
      );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ridwan || Contact</title>
      </Helmet>
      <div className="background">
        <div className="contact-container screen">
          <div className="screen-body">
            <div className="screen-body-item left">
              <div className="app-title">
                <span className="text-roboto">CONTACT with RIDWAN</span>
              </div>
              <div className="app-contact">
                <address>
                  <p className="contact-info">CONTACT INFO :</p>
                  <PhoneIcon style={{ fontSize: "16px" }} />{" "}
                  <a href="tel:+8801521328978">+88 01521-328978</a>
                  <br /> <PhoneIcon style={{ fontSize: "16px" }} />{" "}
                  <a href="tel:+8801521328978">+88 01773-037730</a>
                  <br /> <EmailIcon style={{ fontSize: "16px" }} />{" "}
                  <a href="mailto:ridwanul.alam96@gmail.com">
                    mail@qridwan.com
                  </a>
                  <br /> <EmailIcon style={{ fontSize: "16px" }} />{" "}
                  <a href="mailto:ridwanul.alam96@gmail.com">
                    ridwanul.alam96@gmail.com
                  </a>
                </address>
              </div>
            </div>
            <div className="screen-body-item">
              <div className="app-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="app-form-group">
                    <input
                      className="app-form-control"
                      placeholder="Name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && <span>Name is required</span>}
                  </div>
                  <div className="app-form-group">
                    <input
                      className="app-form-control"
                      placeholder="Subject"
                      {...register("subject", { required: true })}
                    />
                    {errors.subject && <span>Subject is required</span>}
                  </div>
                  <div className="app-form-group">
                    <input
                      className="app-form-control"
                      placeholder="Email"
                      type="email"
                      {...register("email", { required: true })}
                    />
                    {errors.email && <span>Email is required</span>}
                  </div>
                  <div className="app-form-group message">
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      {...register("message", { required: true })}
                    />
                  </div>
                  <input
                    className="app-form-button"
                    type="submit"
                    disabled={isSending}
                    value={isSending ? "Sending..." : "SEND"}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
