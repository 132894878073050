export const gitStory = (dt) => {
  //   console.log("dt: ", dt);
  let data = [];
  dt.forEach((d) => {
    switch (d.type) {
      case "CreateEvent":
        data.push({
          type: "Create",
          date: d.created_at,
          id: d.id,
          repoType: d.public ? "public" : "private",
          message: d.payload.ref + " on 🛡 " + d.repo.name + "",
        });
        break;
      case "PullRequestEvent":
        data.push({
          type: "Pull Request",
          date: d.created_at,
          id: d.id,
          repoType: d.public ? "public" : "private",
          message: "On 🛡 " + d.repo.name + "",
          title: d.payload.pull_request.title,
        });
        break;
      case "PushEvent":
        data.push({
          type: "Push Request",
          date: d.created_at,
          repoType: d.public ? "public" : "private",
          message: d.payload.ref + " on 🛡 " + d.repo.name + "",
          commits: d.payload.commits,
          id: d.id,
        });
        break;
      default:
        break;
    }
  });
  //   console.log("data: ", data);

  return data;
};
