import { getDownloadURL, ref } from "firebase/storage";
import { bucket } from "../firebase";

export const uploadImage = async (file, id) => {
  const storageRef = await ref(
    bucket,
    `/portfolio/projects/id-` + id + "/" + file.name
  );

  console.log("storageRef: ", storageRef);
  // Create a reference to the file in Firebase storage
  //   const fileRef = storageRef.child(file.name);

  // Upload the file to Firebase storage
  //   const snapshot = await storageRef.put(file);
  //    const snapshot = await uploadBytes(storageRef, file);
  // Get the download URL of the file from Firebase storage
  //   const downloadURL = await snapshot.ref.getDownloadURL();
  const downloadURL = await getDownloadURL(storageRef);

  return downloadURL;
};
