import "./Projects.css";
import ProjectsCard from "./ProjectsCard";
import Flip from "react-reveal/Flip";
import GetProjects from "../../firebase/getProjects";
import Loading from "../Home/Loading";
import { Pagination, Stack } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";

const Projects = () => {
  const { projects, projectLoading } = GetProjects();
  const [page, setPage] = React.useState(1);
  const [count] = React.useState(4);
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <div className="projects-page relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ridwan || Projects</title>
      </Helmet>
      <div className="projects-container">
        <Flip top>
          {" "}
          <h1 className="text-roboto text-center text-uppercase">
            Recent Projects
          </h1>
        </Flip>

        {projectLoading ? (
          <Loading />
        ) : (
          projects.slice((page - 1) * count, page * count).map((project) => (
            <>
              <ProjectsCard key={project.id} project={project} />
            </>
          ))
        )}
        <Stack pb={10} mt={5} direction="row" spacing={2} justifyContent="end">
          {projects?.length > count && (
            <Pagination
              count={Math.ceil(projects.length / count)}
              variant="outlined"
              shape="rounded"
              align="center"
              color="primary"
              page={page}
              onChange={handleChange}
            />
          )}
        </Stack>
      </div>
    </div>
  );
};

export default Projects;
