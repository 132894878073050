/* eslint-disable react-hooks/exhaustive-deps */
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../firebase";

const GetProfile = () => {
  const [adminInfo, setAdminInfo] = useState([]);
  useEffect(() => {
    const productsRef = ref(db, "profile/");
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      setAdminInfo(data);
    });
  }, []);
  return { adminInfo };
};

export default GetProfile;
