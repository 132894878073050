import { getDatabase, ref, remove } from "firebase/database";

export const deleteSkill = (id) => {
  const db = getDatabase();
  remove(ref(db, "skills/" + id))
    .then(() => {
      alert("Skill deleted");
    })
    .catch((error) => {
      alert("Something went wrong!\n Try again later.");
    });
};
