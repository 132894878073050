import React from "react";
import moment from "moment";
import { gitStory } from "../../utils/gitStory";
import GithubActivity from "../../Api/getGithubActivity";
import { Box, Stack } from "@mui/material";
import { Divider, Text } from "@mantine/core";
import StarsCanvas from "../canvas/Stars";

const GitActivity = () => {
  const [data] = GithubActivity();
  return (
    <div className="relative z-0">
      {/* //GitHub Activity */}
      <StarsCanvas />
      <Stack spacing={3} m="0 auto" mt={4} className="z-50">
        <Box width="100%" sx={{ background: "black" }}>
          <Text
            fontWeight="bold"
            color={"green.7"}
            sx={{
              textAlign: "center",
              my: 4,
              fontSize: "24px",
            }}
          >
            <strong>Recent Github Activity</strong>
          </Text>
          <Box
            sx={{
              background: "black",
              width: "100%",
              p: 1,
              pb: 10,
              cursor: "cenn",
              height: "360px",
              overflowY: "auto",
              color: "white",
              zIndex: "1000",
            }}
          >
            <Box sx={{ m: "auto", width: { lg: "50%", sm: "80%" } }}>
              {data.length > 0 &&
                gitStory(data)?.map((item, index) => {
                  return (
                    <Box key={item.id} sx={{ py: 1 }}>
                      <Text
                        color={"green.7"}
                        sx={{
                          lineHeight: "1",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.type}
                      </Text>
                      <Text
                        fontWeight={500}
                        color={"white"}
                        sx={{ opacity: 0.5, fontSize: "12px" }}
                        mt={-1}
                      >
                        {moment(item.date).format("Do MMM YYYY, hh:mm A")}
                      </Text>
                      <Text
                        fontSize="12"
                        fontWeight={500}
                        sx={{ opacity: 0.5, fontSize: "14px" }}
                        opacity={0.9}
                        mt={-2}
                        color={"green.7"}
                      >
                        {item?.title}
                      </Text>
                      {item.commits?.map((commit, index) => (
                        <Text
                          key={commit.sha}
                          mt={-1}
                          color={"green.7"}
                          sx={{
                            opacity: 0.9,
                            fontSize: "14px",
                            fontWeight: "medium",
                          }}
                        >
                          Commit: {commit.message}
                        </Text>
                      ))}
                      <Text
                        fontSize="12"
                        fontWeight={500}
                        color={"green.7"}
                        opacity={0.9}
                        mt={1}
                        sx={{
                          opacity: 0.9,
                          fontSize: "14px",
                          fontWeight: "medium",
                          lineHeight: 1.1,
                        }}
                      >
                        {item.message}
                      </Text>
                      <Divider mt={4} sx={{ opacity: 0.6 }} />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Stack>
    </div>
  );
};

export default GitActivity;
