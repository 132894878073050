import React from "react";
// import ridwanGif from "../../assets/images/loading2.svg";
import loading from "../../assets/images/giphy.gif";

const Loading = () => {
  return (
    <div className="loading-container">
      <img src={loading} alt="Loading" className="loading" />
    </div>
  );
};

export default Loading;
