/* eslint-disable react-hooks/exhaustive-deps */
import { onValue, orderByValue, query, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../firebase";

const GetProjects = () => {
  const [projects, setprojects] = useState([]);
  const [projectLoading, setprojectLoading] = useState(true);

  useEffect(() => {
    setprojectLoading(true);
    const productsRef = ref(db, "projects/");

    const topUserPostsRef = query(productsRef, orderByValue("sequence"));

    onValue(topUserPostsRef, (snapshot) => {
      const data = snapshot.val();
      let allProjects = [];
      let array = Object.entries(data);
      array.forEach((project) => {
        allProjects.push({
          ...project[1],
        });
      });
      setprojectLoading(false);
      setprojects(
        allProjects.sort((a, b) => (a.sequence > b.sequence ? 1 : 0))
      );
    });
  }, []);
  return { projects, projectLoading };
};

export default GetProjects;
