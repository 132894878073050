import {
  Badge,
  Button,
  Checkbox,
  Container,
  Group,
  Paper,
  SimpleGrid,
  Stack,
} from "@mantine/core";
import React, { useState } from "react";
import { TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { upperFirst } from "@mantine/hooks";
import { DropzoneButton } from "./UploadImage";
import { AddSharp, CancelOutlined, Delete } from "@mui/icons-material";
import Editor from "../../../shared/Editor";
import { uploadImage } from "../../../firebase/uploadImage";
import { updateProject } from "../../../firebase/editProject";
import GetProjects from "../../../firebase/getProjects";
import { nanoid } from "nanoid";

const AddProject = () => {
  const { projects } = GetProjects();

  //   const {
  //     title,
  //     description,
  //     desc,
  //     gitLink,
  //     img,
  //     liveLink,
  //     id,
  //     tools: techs,
  //   } = project || {};
  const [content, setContent] = useState(() => {});

  const handleChangeEditor = ({ editor }) => {
    setContent(editor.getHTML());
  };
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [tools, setTools] = useState();
  const [newTool, setNewTool] = useState("");
  const [currentImages, setCurrentImages] = useState();
  const form = useForm({
    initialValues: {
      title: "",
      liveLink: "",
      gitLink: "",
      job: true,
      sequence: projects?.length ?? 0 + 1,
    },

    // validate: {
    //   email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
    //   password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
    // },
  });
  const onSubmit = async (val) => {
    const genId = nanoid();
    setIsLoading(true);
    try {
      let urls = await Promise.all(
        selectedImages?.map(async (f) => {
          let url = await uploadImage(f.file, genId);
          return url;
        })
      );
      const dt = {
        title: val.title,
        desc: content,
        id: genId,
        sequence: projects?.length ?? 0 + 1,
        tools: tools,
        gitLink: val.gitLink,
        liveLink: val.liveLink,
        img: currentImages ? [...urls, ...currentImages] : [...urls],
        job: val.job,
      };
      const req = await updateProject(dt);
      setIsLoading(false);
      if (req) console.log("content: imageURLs==>>", req);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleDeleteTool = (item) => {
    setTools((prev) => prev.filter((t) => t !== item));
  };
  const handleImageDelete = (index) => {
    const newImages = [...currentImages];
    newImages.splice(index, 1);
    setCurrentImages(newImages);
  };
  const handleTools = () => {
    setTools((prev) => (prev ? [...prev, newTool] : [newTool]));
    setNewTool("");
  };
  return (
    <Container my={10}>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <h4 className="text-center text-[23px] font-bold mb-4">Add Project</h4>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack>
            {currentImages && (
              <section>
                <h5>Existing Images</h5>
                <SimpleGrid cols={3} mt="md">
                  {/* {items} */}
                  {typeof currentImages === "object" ? (
                    currentImages?.map((img, i) => {
                      return (
                        <div key={i} className="relative">
                          <img
                            src={img}
                            alt={"img"}
                            width="100%"
                            height={250}
                          />
                          <button
                            className=" absolute top-2 right-2"
                            onClick={() => handleImageDelete(i)}
                          >
                            <Delete color="error" />
                          </button>
                        </div>
                      );
                    })
                  ) : currentImages ? (
                    <div className="relative">
                      <img
                        src={currentImages}
                        alt={"img"}
                        width="100%"
                        height={250}
                      />
                      <button
                        className=" absolute top-2 right-2"
                        onClick={() => setCurrentImages("")}
                      >
                        <Delete color="error" />
                      </button>
                    </div>
                  ) : null}
                </SimpleGrid>
              </section>
            )}

            <DropzoneButton
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
            />

            <TextInput
              required
              label="Title"
              placeholder=""
              value={form.values.title}
              onChange={(event) =>
                form.setFieldValue("title", event.currentTarget.value)
              }
              radius="md"
            />

            <div>
              <p className="mb-2 text-sm">
                Description <span className="text-red-600">*</span>
              </p>
              <Editor
                handleChangeEditor={handleChangeEditor}
                content={content}
              />
            </div>
            <TextInput
              label="Code Link"
              placeholder=""
              value={form.values.gitLink}
              onChange={(event) =>
                form.setFieldValue("gitLink", event.currentTarget.value)
              }
              radius="md"
            />
            <TextInput
              label="Live Link"
              placeholder=""
              value={form.values.liveLink}
              onChange={(event) =>
                form.setFieldValue("liveLink", event.currentTarget.value)
              }
              radius="md"
              // error={form.errors.email && "Invalid email"}
            />
            <Group spacing={7} mt={5}>
              {tools?.map((item) => (
                <div className="relative">
                  <Badge
                    color={"gray"}
                    key={item}
                    // leftSection={badge.emoji}
                  >
                    {item}
                  </Badge>
                  <CancelOutlined
                    onClick={() => handleDeleteTool(item)}
                    className="absolute top-[-5px] right-[-2px] cursor-pointer"
                    color="error"
                    fontSize="10px"
                  />
                </div>
              ))}
              <div className="w-[40%] flex mt-2 relative">
                <TextInput
                  placeholder="Add Tool"
                  classNames={{
                    input: "",
                    root: "w-full flex-1",
                  }}
                  value={newTool}
                  onChange={(event) => setNewTool(event.currentTarget.value)}
                />
                <Button
                  onClick={handleTools}
                  className="bg-slate-700 absolute right-0 "
                >
                  <AddSharp />
                </Button>
              </div>
            </Group>
            <Checkbox
              label="Job Experiance"
              color={"gray"}
              checked={form.values.publish}
              onChange={(event) =>
                form.setFieldValue("job", event.currentTarget.checked)
              }
            />
          </Stack>

          <Group position="apart" mt="xl">
            <Button
              disabled={isLoading}
              type="submit"
              className="bg-gray-800 hover:bg-black"
              radius="xl"
            >
              {upperFirst("Submit")}
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
};

export default AddProject;
