"use client";
import React from "react";
import { SparklesCore } from "../../ui/sparkless";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

export function SparklesPreview({ text }) {
  return (
    <div className="h-[18rem] relative w-full bg-white flex flex-col items-center justify-center overflow-hidden rounded-md">
      <h1 className="md:text-5xl text-3xl lg:text-5xl font-bold text-center text-black/75 relative z-20">
        {text}
      </h1>
      <div className="w-[40rem] h-40 relative">
        {/* Gradients */}
        <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
        <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
        <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
        <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

        {/* Core component */}
        <SparklesCore
          background="transparent"
          minSize={0.4}
          maxSize={1}
          particleDensity={1500}
          className="w-full h-full"
          particleColor="#0043c4"
        />

        {/* Radial Gradient to prevent sharp edges */}
        <div className="absolute inset-0 w-full h-full bg-white [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
      </div>

      <ul className="absolute top-18">
        <li>
          <div className="icon is-small mr-2">
            <PermIdentityIcon />
          </div>
          <span> Full Stack Developer</span>
        </li>
        <li>
          <div className="icon is-small mr-2">
            <LocationOnOutlinedIcon />
          </div>
          <span> Chattogram, Bangladesh</span>
        </li>
        <li>
          <div className="icon is-small mr-2 ">
            <SchoolOutlinedIcon />
          </div>
          <span> BSc Graduate </span>
        </li>
      </ul>
    </div>
  );
}
