/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import Slide from "react-reveal/Slide";
import LinkIcon from "@mui/icons-material/Link";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageViewer from "react-simple-image-viewer";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { Box } from "@mui/material";

const ProjectsCard = ({ project }) => {
  const { title, liveLink, gitLink, tools, img, desc } = project;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    open();
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Slide bottom>
      <div className="project shadow-md hover:shadow-xl">
        <picture className="project-image">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4500,
              disableOnInteraction: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {typeof img === "object" ? (
              img.map((src, index) => (
                <SwiperSlide key={src} onClick={() => openImageViewer(index)}>
                  <img src={src} alt={title} style={{ cursor: "pointer" }} />
                  {/* <Image alt={title} src={img} /> */}
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                {/* <Image src={img} alt={title} /> */}
                <img src={img} alt="Random Food Picker Screenshot" />
              </SwiperSlide>
            )}
          </Swiper>
          <Modal
            opened={opened && isViewerOpen}
            onClose={close}
            size={"80%"}
            title={title}
            centered
          >
            {isViewerOpen && (
              <Box sx={{ minHeight: "80vh" }}>
                <ImageViewer
                  backgroundStyle={{ backgroundColor: "black" }}
                  width="100%"
                  height="100%"
                  src={img}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              </Box>
            )}
          </Modal>
        </picture>
        <div className="project-text px-4">
          <h3 className="font-weight-bolder text-center my-3">{title}</h3>
          <div className="project-tags mt-3">
            {tools.map((tool, index) => (
              <div key={index} className={`tag tag-${tool.toLowerCase()}`}>
                {tool}
              </div>
            ))}
          </div>
          {/* <ul className="text-justify py-3">
            {description.map((obj, index) => {
              return <li key={index}>{obj.feature}</li>;
            })}
          </ul> */}
          <div dangerouslySetInnerHTML={{ __html: desc }}></div>
        </div>
        <div className="flex justify-end gap-2">
          {liveLink && (
            <a
              className="project-button no-underline"
              href={liveLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <LinkIcon className="" />
            </a>
          )}
          {gitLink && (
            <a
              className="project-button no-underline"
              href={gitLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <GitHubIcon className="" />
            </a>
          )}
        </div>
      </div>
    </Slide>
  );
};

export default ProjectsCard;
