import React, { useEffect } from "react";

const GithubActivity = () => {
  const [data, setData] = React.useState([]);
  useEffect(() => {
    fetch("https://api.github.com/users/qridwan/events", {
      method: "get",
      headers: {
        Authorization: process.env.REACT_APP_GITHUB_TOKEN,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        // process JSON response here
        // console.log({ myJson });
        setData(myJson);
      });
  }, []);
  return [data];
};

export default GithubActivity;
