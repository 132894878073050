import React from "react";
import { Link } from "react-router-dom";
import style from "./footer.module.css";

const Footer = () => {
  return (
    <div className={["bg-black z-0 relative", style.footer].join(" ")}>
      <footer className="w-[90%] md:w-[60%] m-auto flex justify-between mx-3 text-light">
        <div className="md:py-1 ">
          {" "}
          <small className="text-xs">
            All{" "}
            <Link className="no-underline" to="/admin">
              rights{" "}
            </Link>{" "}
            reserved &copy;{" "}
            <Link className="no-underline " to="/contact">
              qridwan
            </Link>
          </small>
        </div>
        <div className="md:py-1 offset-0 md:offset-1 hidden md:block">
          <Link className="no-underline " to="/contact">
            <small className="text-light font-thin text-xs">
              Contact with RIDWAN
            </small>
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
