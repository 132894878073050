/* eslint-disable eqeqeq */
import {
  CancelOutlined,
  DeleteForever,
  EditOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import { uploadDocument } from "../../../Api/uploadFile";
import { deleteSkill } from "../../../firebase/deleteSkill";
import { SetSkill } from "../../../firebase/setSkills";

const TableRow = ({ row }) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [skill, setSkill] = React.useState(row.name);
  //   const [imgUrl, setImgUrl] = React.useState("");
  const [img, setImg] = React.useState(row.img);
  const [previewImg, setPreviewImg] = React.useState("");
  const [type, setType] = React.useState(row.type);
  //   const [fileUrl, isLoading] = UploadFile(
  //     img,
  //     `/portfolio/skills/${row.name}`
  //   );

  const handleImageChange = (file) => {
    setImg(file);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setPreviewImg(reader.result);
      }
    };
    reader.readAsDataURL(file);

    // setImgUrl(url);
  };

  const handleEditSave = async () => {
    if (previewImg) {
      const url = uploadDocument(img, `/portfolio/skills/${row.id}`);
      url.then((fileUrl) => {
        // console.log('res: ', res);
        // console.log({ name: skill, type, image: fileUrl, id: skill + type });
        setIsEdit(!isEdit);
        SetSkill({
          name: skill,
          type,
          image: fileUrl ?? row.image,
          id: row.id,
        }).then((res) => {
          //   console.log("res: ", res);
          alert("Update Successfull!");
        });
      });
    } else {
      setIsEdit(!isEdit);
      SetSkill({
        name: skill,
        type,
        image: row.image,
        id: row.id,
      }).then((res) => {
        alert("Update Successfull!");
      });
    }
  };
  const res = (
    <tr key={row.name}>
      <td>
        {isEdit ? (
          <Box sx={{ overflow: "hidden" }}>
            <img
              src={previewImg ? previewImg : row.image}
              height="60px"
              alt="skill"
              style={{ margin: "10px 0" }}
            />
            <input
              type="file"
              accept="image/*"
              style={{ marginTop: "10px" }}
              onChange={(e) => handleImageChange(e.target.files[0])}
            />
          </Box>
        ) : (
          <img alt={row.name} src={row.image} height="60px" width="60px" />
        )}
      </td>
      <td>
        {isEdit ? (
          <TextField
            onChange={(e) => {
              setSkill(e.target.value);
            }}
            id="standard-basic"
            label=""
            variant="standard"
            defaultValue={row.name}
          />
        ) : (
          row.name
        )}
      </td>
      <td>
        {isEdit ? (
          <TextField
            id="standard-select-currency-native"
            select
            label=""
            onChange={(e, val) => {
              setType(e.target.value);
            }}
            defaultValue={row.type}
            SelectProps={{
              native: true,
            }}
            helperText=""
            variant="standard"
          >
            <option value={"development"}>Development</option>
            <option value={"design"}>Design</option>
            <option value={"tools"}>Tools</option>
          </TextField>
        ) : (
          row.type.toUpperCase()
        )}
      </td>
      <td>
        <Stack direction="row" spacing={2}>
          {isEdit && (
            <>
              <Button
                startIcon={<SaveOutlined />}
                variant="outlined"
                size="sm"
                color="success"
                onClick={handleEditSave}
              >
                Save
              </Button>
              <IconButton
                // variant="outlined"
                size="sm"
                color="error"
                onClick={() => setIsEdit(!isEdit)}
              >
                <CancelOutlined />
              </IconButton>
            </>
          )}
          {!isEdit && (
            <>
              {" "}
              <Button
                startIcon={<EditOutlined />}
                variant="outlined"
                size="sm"
                color="success"
                onClick={() => setIsEdit(!isEdit)}
              >
                Edit
              </Button>
              <Button
                startIcon={<DeleteForever />}
                variant="outlined"
                size="sm"
                color="error"
                onClick={() => deleteSkill(row.id)}
              >
                Delete
              </Button>
            </>
          )}
        </Stack>
      </td>
    </tr>
  );
  return res;
};

export default TableRow;
