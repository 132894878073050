/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdminDrawer from "./AdminDrawer";

const Admin = () => {
  document.title = "Admin || Ridwan";
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/admin") {
      navigate("/admin/profile");
    }
  }, [pathname]);

  return (
    <div className="container">
      <AdminDrawer />
    </div>
  );
};

export default Admin;
