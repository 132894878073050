import React from "react";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import { SparklesPreview } from "./SparklessPreview";

const Header = ({ adminInfo }) => {
  const { name, photoURL } = adminInfo;
  return (
    <div className="home-top-container">
      <div className="home-top-left">
        <Fade left big cascade>
          <picture className="home-top-left-image">
            <source srcSet={photoURL} />
            <source srcSet={photoURL} />
            <img src={photoURL} alt="Ridwan" />
          </picture>
        </Fade>
      </div>
      <div className="home-top-right">
        <Flip top>
          {/* <h1 className="text-krona md-mt-5">{name}</h1> */}
          <SparklesPreview text={name} />
        </Flip>
      </div>
    </div>
  );
};

export default Header;
