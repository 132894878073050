import React from "react";
import GetProjects from "../../../firebase/getProjects";
import { Box, Button, Grid, Stack } from "@mui/material";
import {
  Card,
  Image,
  Text,
  Group,
  Badge,
  createStyles,
  Container,
  Paper,
} from "@mantine/core";
import { Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { encryptObject } from "../../../utils/crypto";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    height: "100%",
    maxWidth: "360px",
    overflow: "hidden",
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: "uppercase",
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

const SetProjects = () => {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const { projects } = GetProjects();

  return (
    <Container my={10}>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack alignItems={"center"} gap={20} m={4}>
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={() => navigate("/admin/add-project")}
          >
            Add Project
          </Button>
        </Stack>
        <Grid container justifyContent={"space-evenly"} spacing={2}>
          {projects
            ?.sort((a, b) => (a.sequence > b.sequence ? 1 : 0))
            .map((project) => {
              return (
                <Grid item key={project.id} xs={12} md={5}>
                  <Card withBorder radius="md" p="md" className={classes.card}>
                    <Card.Section>
                      {/* <Image src={project.img} alt={project.title} height={180} /> */}
                      <picture style={{ width: "250px", overflow: "hidden" }}>
                        <Swiper
                          slidesPerView={1}
                          // spaceBetween={30}
                          loop={true}
                          pagination={{
                            clickable: true,
                          }}
                          autoplay={{
                            delay: 4500,
                            disableOnInteraction: true,
                          }}
                          navigation={true}
                          modules={[Autoplay, Pagination, Navigation]}
                          className="mySwiper"
                        >
                          {typeof project.img === "object" ? (
                            project.img?.map((src) => (
                              <SwiperSlide key={src}>
                                <Image
                                  src={src}
                                  alt={project.title}
                                  // height={180}
                                  // width={180}
                                />
                              </SwiperSlide>
                            ))
                          ) : (
                            <SwiperSlide>
                              <Image
                                src={project.img}
                                alt={project.title}
                                height={180}
                              />
                            </SwiperSlide>
                          )}
                        </Swiper>
                      </picture>
                    </Card.Section>

                    <Card.Section className={classes.section} mt="md">
                      <Group position="apart">
                        <Text size="lg" weight={500}>
                          {project.title}
                        </Text>
                        <Badge size="sm">ID: {project.sequence}</Badge>
                      </Group>
                      <Text size="sm" mt="xs">
                        {project?.description?.map((desc) => (
                          <Box key={desc.feature}>{desc.feature}</Box>
                        ))}
                      </Text>
                      <div
                        dangerouslySetInnerHTML={{ __html: project?.desc }}
                      ></div>
                      {/* {project?.desc?.replace(/"/g, "")} */}
                    </Card.Section>

                    <Card.Section className={classes.section}>
                      <Text mt="md" className={classes.label} color="dimmed">
                        Perfect for you, if you enjoy
                      </Text>
                      <Group spacing={7} mt={5}>
                        {project?.tools?.map((item) => (
                          <Badge
                            color={
                              theme.colorScheme === "dark" ? "dark" : "gray"
                            }
                            key={item}
                            // leftSection={badge.emoji}
                          >
                            {item}
                          </Badge>
                        ))}
                      </Group>
                    </Card.Section>

                    <Group mt="xs">
                      <Button
                        className="bg-gray-800"
                        radius="md"
                        style={{ flex: 1 }}
                        onClick={() =>
                          navigate(
                            "/admin/edit-projects?" + encryptObject(project)
                          )
                        }
                      >
                        Edit Profect
                      </Button>
                    </Group>
                  </Card>
                  {/* <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
            >
              <img src={project.img} alt={project.title} />
              <Box p="6">
                <Box display="flex" alignItems="baseline">
                  <Wrap>
                    {project?.tools.map((item) => (
                      <WrapItem key={item}>
                        <Badge
                          id={item}
                          borderRadius="full"
                          px="2"
                          colorScheme="teal"
                        >
                          {item}
                        </Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                  <Box
                    color="gray.700"
                    fontWeight="bold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                  >
                    ID: {project.id}
                  </Box>
                </Box>

                <Box
                  my="2"
                  fontWeight="semibold"
                  as="h3"
                  lineHeight="tight"
                  isTruncated
                >
                  {project.title}
                </Box>
                {project.description.map((desc) => (
                  <Box key={desc.feature}>{desc.feature}</Box>
                ))}
                <Box display="flex" mt="2" alignItems="center">
                
                </Box>
              </Box>
              <Divider my={4} orientation="horizontal" />
              <Button onClick={() => handleEditOpen(project)}>Edit</Button>
            </Box> */}
                </Grid>
              );
            })}
        </Grid>
      </Paper>

      {/* <EditProject
        isOpen={isOpen}
        firstField={firstField}
        editItem={editItem}
        onClose={onClose}
      /> */}
    </Container>
  );
};

export default SetProjects;
