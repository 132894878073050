import React from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Fade from "react-reveal/Fade";
import { Modal, Text, Center } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { TextGenerateEffect } from "../../ui/text-generate-effect";

const About = ({ adminInfo }) => {
  const { about, github, linkedin, resume } = adminInfo;
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div className="home-bottom pb-4">
      <div className="home-bottom-text ">
        <Fade cascade>
          <div>
            {/* <p>{about}</p> */}
            <TextGenerateEffect words={about} />
          </div>
        </Fade>

        <div className="home-bottom-buttons">
          <a href={github} target="_blank" rel="noreferrer noopener">
            <div className="button github">
              <span className="icon is-small">
                <GitHubIcon className="" />
              </span>
              Github
            </div>
          </a>
          <>
            <div onClick={open} className="button CV">
              <span className="  is-small">
                <GetAppIcon className="" />
              </span>
              RESUME
            </div>
            <Modal
              opened={opened}
              onClose={close}
              size={"xl"}
              title={<Text align="center">Ridwan Resume</Text>}
              centered
            >
              <Center>
                <div
                  onClick={() =>
                    window.open(
                      `https://drive.google.com/uc?export=download&id=${resume}`,
                      "_blank"
                    )
                  }
                  className="button github  m-auto"
                >
                  <span className="  is-small">
                    <GetAppIcon className="" />
                  </span>
                  Download
                </div>
              </Center>
              <iframe
                title="ridwan_resume"
                src={`https://drive.google.com/file/d/${resume}/preview`}
                width="100%"
                height="780"
                allow="autoplay"
              ></iframe>
            </Modal>
          </>
          <a href={linkedin} target="_blank" rel="noreferrer noopener">
            <div className="button linkedin">
              <span className="icon is-small">
                <LinkedInIcon className="mui-icon" />
              </span>
              LinkedIn
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
