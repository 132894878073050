/* eslint-disable react-hooks/exhaustive-deps */
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../firebase";

const GetSkills = () => {
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    const productsRef = ref(db, "skills/");
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      let allSkills = [];
      let array = Object.entries(data);
      array.forEach((sk) => {
        allSkills.push({
          ...sk[1],
        });
      });
      //sorting for showing development skills first
      setSkills(
        allSkills.sort((a, b) =>
          a.type === "development" && b.type !== "development" ? -1 : 1
        )
      );
    });
  }, []);
  return { skills };
};

export default GetSkills;
