// Import the necessary dependencies
import CryptoJS from 'crypto-js';
// Define a secret key for encryption/decryption
const SECRET_KEY = "qridwan";

export function encryptObject(obj) {
 // Convert the object to a string
 const objString = JSON.stringify(obj);
 // Encrypt the object string with AES using the secret key
 const encrypted = CryptoJS.AES.encrypt(objString, SECRET_KEY);
 // Return the encrypted object as a string
 return encrypted.toString();
}

// Decrypts an encrypted string back to an object
export function decryptObject(encryptedObjString) {
   // Decrypt the object string with AES using the secret key
   const decrypted = CryptoJS.AES.decrypt(encryptedObjString, SECRET_KEY);
   // Convert the decrypted object string to JSON
   const objString = decrypted.toString(CryptoJS.enc.Utf8);
   // Parse the JSON string to an object
   const obj = JSON.parse(objString);
   // Return the decrypted object
   return obj;
}
