import React from "react";
import { Tabs } from "@mantine/core";
// import { IconPhoto, IconMessageCircle, IconSettings } from "@tabler/icons";
import { AiTwotoneThunderbolt } from "react-icons/ai";
import { Icon, Stack } from "@mui/material";
import { BiDesktop } from "react-icons/bi";
import { BsTools } from "react-icons/bs";
import { GiSpiderWeb } from "react-icons/gi";
import { ItemCard } from "./ItemCard";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { textVariant } from "../../utils/motion";
const Skills = () => {
  const largeScreen = useMediaQuery("(min-width: 900px)");

  return (
    <>
      <div className="container d-flex flex-column  justify-content-center ">
        <motion.div variants={textVariant()}>
          <Stack alignItems={"center"} my={2}>
            <h1 mt={0} mb={1} className="text-roboto text-uppercase">
              Tech Stack
            </h1>
            <p className="text-[16px]">
              A list of my favorite tools and technologies that I use on a
              regular basis.
            </p>
          </Stack>{" "}
        </motion.div>
        <Tabs
          orientation={largeScreen ? "vertical" : "horizontal"}
          defaultValue="all"
          placement="right"
          color={"gray.9"}
        >
          <Tabs.List mb={6} ml={largeScreen && 8}>
            <Tabs.Tab
              value="all"
              icon={largeScreen && <Icon component={AiTwotoneThunderbolt} />}
            >
              All
            </Tabs.Tab>
            <Tabs.Tab
              value="development"
              icon={largeScreen && <Icon component={BiDesktop} />}
            >
              Web Dev.
            </Tabs.Tab>
            <Tabs.Tab
              value="design"
              icon={largeScreen && <Icon component={GiSpiderWeb} />}
            >
              Web Design
            </Tabs.Tab>
            <Tabs.Tab
              value="tools"
              icon={largeScreen && <Icon component={BsTools} />}
            >
              Tools
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="all" pl="xs">
            <ItemCard type={"all"} />
          </Tabs.Panel>

          <Tabs.Panel value="development" pl="xs">
            <ItemCard type={"development"} />
          </Tabs.Panel>

          <Tabs.Panel value="design" pl="xs">
            <ItemCard type={"design"} />
          </Tabs.Panel>
          <Tabs.Panel value="tools" pl="xs">
            <ItemCard type={"tools"} />
          </Tabs.Panel>
        </Tabs>
      </div>
    </>
  );
};
export default SectionWrapper(Skills, "Skills");
