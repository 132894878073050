import { useEffect, useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Container,
  Paper,
} from "@mantine/core";
import { keys } from "@mantine/utils";
import {
  AddCard,
  ArrowDownward,
  ArrowUpward,
  SearchRounded,
  SelectAll,
} from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import TableRow from "./TableRow";
import GetSkills from "../../../firebase/getSkills";
import AddNewSkill from "./AddSkill";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "10px !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: 100,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted ? (
    reversed ? (
      <ArrowUpward />
    ) : (
      <ArrowDownward />
    )
  ) : (
    <SelectAll />
  );
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            {Icon}
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}

export default function AdminSkills() {
  const [search, setSearch] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const { skills: data } = GetSkills();
  const [scrolled, setScrolled] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const { classes, cx } = useStyles();
  useEffect(() => {
    console.log("setSortedData: ");
    setSortedData(data);
  }, [data]);
  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(data, { sortBy, reversed: reverseSortDirection, search: value })
    );
  };

  const rows = sortedData?.map((row) => <TableRow key={row.id} row={row} />);

  return (
    <Container my={10}>
      <Paper
        withBorder
        shadow="md"
        p={30}
        mt={20}
        radius="md"
        sx={{ overflowX: "hidden" }}
      >
        <ScrollArea
          sx={{ height: "80vh", overflowX: "hidden !important" }}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <TextInput
            placeholder="Search by any field"
            mb="md"
            icon={<SearchRounded />}
            value={search}
            onChange={handleSearchChange}
          />
          <Stack direction="row" justifyContent={"end"} mx={2}>
            {isAdd ? (
              <AddNewSkill isAdd={isAdd} setIsAdd={setIsAdd} />
            ) : (
              <Button
                variant="outlined"
                color="success"
                sx={{ my: 2 }}
                startIcon={<AddCard />}
                onClick={() => setIsAdd(!isAdd)}
              >
                Add New Skill
              </Button>
            )}
          </Stack>
          <Table
            horizontalSpacing="md"
            verticalSpacing="xs"
            sx={{ tableLayout: "fixed", minWidth: 700, overflowX: "hidden" }}
          >
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <Th
                //   sorted={sortBy === "name"}
                //   reversed={reverseSortDirection}
                //   onSort={() => setSorting("")}
                >
                  Image
                </Th>
                <Th
                  sorted={sortBy === "name"}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting("name")}
                >
                  Skill
                </Th>
                <Th
                  sorted={sortBy === "type"}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting("type")}
                >
                  Type
                </Th>
                <Th
                //   sorted={sortBy === "company"}
                //   reversed={reverseSortDirection}
                //   onSort={() => setSorting("company")}
                >
                  Action
                </Th>
              </tr>
            </thead>
            <tbody>
              {rows.length > 0 ? (
                rows
              ) : (
                <tr>
                  <td colSpan={data.length > 0 && Object.keys(data[0])?.length}>
                    <Text weight={500} align="center">
                      Nothing found
                    </Text>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ScrollArea>
      </Paper>
    </Container>
  );
}
