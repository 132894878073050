import React, { useEffect, useState } from "react";
import "./Blogs.css";
import Flip from "react-reveal/Flip";
import { Helmet } from "react-helmet";
import { ThreeDCardDemo } from "./BlogPreviewV2";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@thizizrid"
    )
      .then((resp) => resp.json())
      .then((data) => {
        setBlogs(data.items);
      });
  }, []);

  return (
    <div className="blog-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ridwan || Blog</title>
      </Helmet>
      <Flip top>
        <h1 className="text-uppercase text-roboto">My Blogs</h1>
      </Flip>
      <div className="row w-75 mx-auto justify-center ">
        {blogs?.map((blog) => {
          return (
            <div
              key={blog.id}
              class="col-md-6 col-lg-4 col-xl-3 col-sm-12 mt-4 "
            >
              {/* <BlogCardV2 blog={blog} /> */}
              {/* <FollowingPointerDemo blog={blog} /> */}
              <ThreeDCardDemo blog={blog} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blogs;
