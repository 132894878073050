import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import navStyle from "./Navigation.module.css";
import HomeIcon from "@mui/icons-material/Home";
import DvrIcon from "@mui/icons-material/Dvr";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
// import ArticleIcon from '@mui/icons-material/Receipt';
import WysiwygIcon from "@mui/icons-material/Wysiwyg";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const activeClass = navStyle.active;
  return (
    <div className={navStyle.nav_container}>
      <ul>
        <li
          className={splitLocation[1] === "" ? activeClass : ""}
          onClick={() => navigate("/")}
        >
          {/* <Link to="/" className="no-underline"> */}
          <span className="icon is-small mr-2">
            <HomeIcon />
          </span>
          <span className="nav-text">Home</span>
          {/* </Link> */}
        </li>
        <li
          className={splitLocation[1] === "projects" ? activeClass : ""}
          onClick={() => navigate("/projects")}
        >
          {/* <Link className="no-underline" to="projects"> */}
          <span className="icon is-small mr-2">
            <DvrIcon className="nav-icon" />
          </span>
          <span className="nav-text">Projects</span>
          {/* </Link> */}
        </li>
        <li
          className={splitLocation[1] === "blogs" ? activeClass : ""}
          onClick={() => navigate("/blogs")}
        >
          {/* <Link className="no-underline" to="blogs"> */}
          <span className="icon is-small mr-2">
            <WysiwygIcon />
          </span>
          <span className="nav-text">Blog</span>
          {/* </Link> */}
        </li>
        <li
          className={splitLocation[1] === "contact" ? activeClass : ""}
          onClick={() => navigate("/contact")}
        >
          {/* <Link className="no-underline" to="contact"> */}
          <span className="icon is-small mr-2">
            <PermPhoneMsgIcon />{" "}
          </span>
          <span className="nav-text">Contact</span>
          {/* </Link> */}
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
